import { PiGasPumpLight, PiEngineLight } from "react-icons/pi";
import TransmissionSvg from "../assets/svg/transmission.svg";
import { Link } from "react-router-dom";

import CurrencyFormmater from "./CurrencyFormatter";
import CurrencyFormatter from "./CurrencyFormatter";

const CarCard = ({
  id,
  tag,
  title,
  engine,
  fuel,
  transmission,
  price,
  banner,
}) => {
  return (
    <div className="rounded-lg overflow-hidden border group border-gray-200/70 ">
      <div className="bg-slate-100/80 overflow-hidden">
        <img
          src={banner}
          className="w-full object-cover duration-150 group-hover:scale-110 h-[180px]"
          loading="lazy"
          alt=""
        />
      </div>
      <div className="mx-7">
        {/* title */}
        <div className="py-3 space-y-1">
          <h5 className="font-medium text-black/80 text-xl">{title}</h5>
          {tag && <p className="text-gray-800/80 text-sm truncate">{tag}</p>}
        </div>
        <div className="h-px w-full bg-gray-200/80"></div>

        {/* tiles */}
        <div className="flex items-center justify-between py-3 text-gray-800/80 text-sm capitalize">
          {engine && (
            <div className="flex items-center justify-center flex-col">
              <PiEngineLight className="h-6 w-6" />
              <p>{engine}</p>
            </div>
          )}

          {fuel && (
            <div className="flex items-center justify-center flex-col">
              <PiGasPumpLight className="h-6 w-6" />
              <p>{fuel}</p>
            </div>
          )}

          {transmission && (
            <div className="flex items-center justify-center flex-col">
              <img
                src={TransmissionSvg}
                loading="lazy"
                className="h-5 w-4 mb-1"
                alt=""
              />
              <p>{transmission}</p>
            </div>
          )}
        </div>
        {(engine || transmission || fuel) && (
          <div className="h-px w-full bg-gray-200/80"></div>
        )}

        <div className="flex items-center justify-between py-3">
          {/* price */}
          <h4 className="font-semibold">
            {isNaN(price) || price === null
              ? "Coming Soon"
              : CurrencyFormatter.format(price)}
          </h4>

          <div>
            <Link to={`/vehicles/${id}`} className="text-blue-500/90 text-sm">
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarCard;
