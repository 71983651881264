import { useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { RiSteering2Line } from "react-icons/ri";

import Awards from "./screens/Awards";
import Channel from "./screens/Channel";
import Detail from "./screens/Detail";
import Gallery from "./screens/Gallery";
import Home from "./screens/Home";
import Outlets from "./screens/Outlets";
import Thankyou from "./screens/Thankyou";
import Contact from "./screens/contact";
import BookAService from "./screens/services/book";
import Finance from "./screens/services/finance";
import Insurance from "./screens/services/insurance";
import Truevalue from "./screens/services/truevalue";

import whatsappSvg from "./assets/whatsapp.svg";
import About from "./screens/About";
import Offers from "./screens/Offers";
import EVitara from "./screens/e-vitara";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";

function App() {
  const ScrollTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };
  return (
    <div className="relative">
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/vehicles/:id" element={<Detail />} />
        <Route path="/vehicles/e-vitara" element={<EVitara />} />
        <Route path="/channels/:id" element={<Channel />} />
        <Route path="/book-a-service" element={<BookAService />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/truevalue" element={<Truevalue />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/outlets" element={<Outlets />} />
        <Route path="*" element={"404 - Not Found"} />
      </Routes>

      <div class="fixed z-20 space-y-2 -translate-y-1/2 right-1 bottom-1/3 lg:right-0 ">
        <Link
          to={"/book-a-service"}
          class="flex items-center flex-grow gap-2 px-4 py-2 text-sm text-center text-white duration-300 delay-100 translate-x-32 bg-red-500 border border-red-400 rounded-l-full shadow-lg cursor-pointer hover:translate-x-0 group"
        >
          <HiMiniWrenchScrewdriver className="h-5 w-5" />

          <p class="opacity-0 group-hover:opacity-100"> Book A Service</p>
        </Link>
        <Link
          to={"/contact-us"}
          class="flex items-center flex-grow gap-2 px-4 py-2 text-sm text-center text-white duration-300 delay-100 translate-x-32 bg-red-500 border border-red-400 rounded-l-full shadow-lg cursor-pointer hover:translate-x-0 group"
        >
          <RiSteering2Line className="h-6 w-6" />

          <p class="opacity-0 group-hover:opacity-100"> Book A Test Drive</p>
        </Link>
      </div>

      <div className="fixed bottom-5 right-5 z-[9999]">
        <a
          href="https://api.whatsapp.com/send/?phone=+919912341531&text=Hello%20%F0%9F%91%8B%20Welcome%20to%20GEM%20Motors!%20What's%20your%20name%3F&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="bg-green-600 p-2.5 rounded-full animate-bounce">
            <img
              src={whatsappSvg}
              loading="lazy"
              className="h-6 w-6"
              alt="whatsapp"
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default App;
