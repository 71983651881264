import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";
import EnquiryForm from "../components/form/Enquiry";
import Header from "../components/navbar/Header";
import Slider from "../components/slider/Slider";

export const banners = [
  {
    img: require("../assets/e-vitara/1.png"),
  },
  {
    img: require("../assets/e-vitara/2.png"),
  },
  {
    img: require("../assets/e-vitara/3.png"),
  },
  {
    img: require("../assets/e-vitara/4.png"),
  },
  {
    img: require("../assets/e-vitara/5.png"),
  },
  {
    img: require("../assets/e-vitara/6.png"),
  },
];

const EVitara = () => {
  return (
    <>
      <div className="bg-gray-50">
        <Header />
        <div className="max-w-screen-xl mx-auto my-10 px-5">
          <p className="font-medium text-gray-600/90 my-8">
            <Link to={"/"}>Home</Link> /&nbsp;
            <span className=" capitalize">Nexa</span>&nbsp;/&nbsp;
            <span className="text-red-600">e Vitara</span>
          </p>

          <div className="mb-5 space-y-1">
            <h2 className="xl:text-4xl font-semibold ">e VITARA</h2>
            <p>Spark Your eMagination</p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <div className="lg:col-span-2 col-span-2 space-y-10">
              {/* Slider */}
              <div className="rounded-lg overflow-hidden h-[200px] md:h-[500px]">
                <Slider sliders={banners} />
              </div>

              <a
                href={require("../assets/e-vitara/E-Vitara.pdf")}
                target={"_blank"}
                rel="noreferrer noopener "
                className="bg-blue-700 shadow text-white flex items-center justify-center font-medium w-full rounded-lg py-3"
              >
                Download brochure
              </a>
            </div>

            {/* price and enquiry form */}
            <div className="col-span-1 space-y-5">
              <div className="border border-gray-200 bg-white p-8 rounded-lg">
                <p className="text-gray-900/90 mb-2">
                  Our Price <small>starts at</small>
                </p>
                <h1 className="font-semibold text-3xl mb-5">
                  Coming Soon
                  <span className="text-red-600">*</span>
                </h1>
                <a
                  href="#form"
                  className="bg-red-600 shadow text-white flex items-center justify-center font-medium w-full rounded-lg py-3"
                >
                  Buy
                </a>
              </div>

              <div className="border border-gray-200 bg-white p-8 rounded-lg">
                <EnquiryForm />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default EVitara;
