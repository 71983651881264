import React, { useState } from "react";
import Header from "../components/navbar/Header";
import Footer from "../components/footer/Footer";
import Banner from "../components/banner/Banner";
import CarCard from "../components/Card";
import { models } from "../json";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Home = () => {
  const [channel, setChannel] = useState(0);

  const channels = ["Arena", "Nexa", "Commercial", "Suzuki"];
  return (
    <div className="">
      <Helmet>
        <title>Maruti Suzuki Car Dealers in Hyderabad | Gem Maruti</title>
        <meta
          name="title"
          content="Maruti Suzuki Car Dealers in Hyderabad | Gem Maruti"
        />
        <meta
          name="description"
          content="Maruti Suzuki Car Dealers in Hyderabad. Gem Maruti is one of the top leading Maruti Car delaer in Hyderabad & Secunderabad. Visit now"
        />
      </Helmet>
      <Header />
      <Banner />
      <div className="max-w-screen-xl mx-auto px-5">
        {/* channels */}
        <div className="mt-10 mb-5">
          <h3 className="xl:text-4xl font-bold">Explore our channels</h3>

          <div className="my-8">
            <div className="flex items-center flex-wrap gap-5 border-b border-gray-200/80">
              {channels.map((item, index) => (
                <div
                  className={classNames(
                    channel === index &&
                      "bg-transparent border-b-2 border-red-600",
                    "font-medium cursor-pointer p-2"
                  )}
                  onClick={() => setChannel(index)}
                  key={index}
                >
                  {item}
                </div>
              ))}

              <div className={"bg-transparent font-medium cursor-pointer p-2"}>
                <Link to={"/truevalue"}>Truevalue</Link>
              </div>
            </div>
          </div>

          {/* section */}
          <div>
            {channel === 0 && (
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                {models.map(
                  (item, index) =>
                    item.category === "arena" && (
                      <CarCard
                        key={index}
                        id={item?.id}
                        tag={item?.tag}
                        banner={item?.pic}
                        title={item?.name}
                        price={item?.price}
                        fuel={item?.fuel}
                        transmission={item?.transmission}
                        engine={item?.cc}
                      />
                    )
                )}
              </div>
            )}
            {channel === 1 && (
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                <CarCard
                  tag={"Spark Your eMagination"}
                  banner={require("../assets/e-vitara/E-Vitara_Tiles.webp")}
                  title={"E Vitara"}
                  price={"Coming Soon"}
                  id={"e-vitara"}
                />
                {models.map(
                  (item, index) =>
                    item.category === "nexa" && (
                      <CarCard
                        key={index}
                        id={item?.id}
                        tag={item?.tag}
                        banner={item?.pic}
                        title={item?.name}
                        price={item?.price}
                        fuel={item?.fuel}
                        transmission={item?.transmission}
                        engine={item?.cc}
                      />
                    )
                )}
              </div>
            )}
            {channel === 2 && (
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                {models.map(
                  (item, index) =>
                    item.category === "commercial" && (
                      <CarCard
                        key={index}
                        id={item?.id}
                        tag={item?.tag}
                        banner={item?.pic}
                        title={item?.name}
                        price={item?.price}
                        fuel={item?.fuel}
                        transmission={item?.transmission}
                        engine={item?.cc}
                      />
                    )
                )}
              </div>
            )}

            {channel === 3 && (
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                {models.map(
                  (item, index) =>
                    item.category === "suzuki" && (
                      <CarCard
                        key={index}
                        id={item?.id}
                        tag={item?.tag}
                        banner={item?.pic}
                        title={item?.name}
                        price={item?.price}
                        fuel={item?.fuel}
                        transmission={item?.transmission}
                        engine={item?.cc}
                      />
                    )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
